.app {
  height: 100vh;
  width: inherit;
  // min-height: inherit;
  overflow: hidden;
  position: relative;

  &__bg {
    @media screen and (max-width: 1200px) {
      &-bg {
        background-color: #5e17eb !important;
      }

      &-fg {
        display: none !important;
      }
    }

    &-bg {
      position: absolute;
      z-index: -2;
      background-color: #5615d6;
      height: 100vh;
      width: 100vw;
    }

    &-fg {
      position: absolute;
      margin-top: -100px;
      z-index: -1;
      width: 100vw;
      height: 200vh;
      background-color: #5e17eb;
      border-radius: 0 100% 0 0;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-subtitle {
      font-weight: 600;
      color: white;
      font-size: 18pt;
      font-family: "Karla", sans-serif;
      display: flex;
      align-items: baseline;
    }

    @media screen and (max-width: 1200px) {
      &-subtitle {
        font-size: 10pt;
      }
    }
  }

  &__img {
    width: 100%;
    &-container {
      min-width: 300px;
      max-width: 800px;
      width: 30%;
      margin-bottom: 60px;
    }
  }
}
