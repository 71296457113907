body {
  margin: 0;

  font-family: "Karla", sans-serif;
  #root {
    height: auto;
    min-height: 100vh;
    width: 100vw;
  }
}
